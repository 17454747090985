import React from "react";

import FormattedDate from "@src/components/FormattedDate";

const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] as const;
type DayOfWeek = typeof DAYS_OF_WEEK[number];

export type FranceContactInfoProps = {
  from: DayOfWeek;
  to?: DayOfWeek;
};

export class DaysRange extends React.PureComponent<FranceContactInfoProps> {
  public render() {
    const { from, to } = this.props;

    return (
      <>
        <FormattedDate date={this.getDate(from)} format="cccc" />
        {to ? (
          <>
            {" - "}
            <FormattedDate date={this.getDate(to)} format="cccc" />
          </>
        ) : null}
        {": "}
      </>
    );
  }

  private getDate(day: DayOfWeek): Date {
    const date = new Date();
    const dayToSet = DAYS_OF_WEEK.indexOf(day);
    if (dayToSet === -1) {
      throw new Error(
        `Invalid day '${day}' of the week is passed, expected one of: ${DAYS_OF_WEEK}`,
      );
    }
    const currentDay = date.getDay();
    const distance = dayToSet - currentDay;
    date.setDate(date.getDate() + distance);
    return date;
  }
}

export default DaysRange;
