import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import ContactUsTemplate from "./ContactUsTemplate";

export type ContactUsPageProps = PageWithImagesProps<
  "france" | "romania" | "eilat" | "spain"
> & {};

class ContactUsPage extends React.Component<ContactUsPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Contact us")}
          description={t(
            "We are always happy to hear from you! For any questions or recommendations do not hesitate to contact us.",
          )}
          keywords={[t("contact us")]}
        />
        <ContactUsTemplate />
      </IndexLayout>
    );
  }
}

export default ContactUsPage;
