import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { ContactUsPageProps } from "@src/templates/ContactUsPage";

//tslint:disable: no-relative-imports
import ContactInfoBlock from "../ContactInfoBlock";
import DaysRange from "../DaysRange";
import TimeRange from "../TimeRange";
//tslint:enable: no-relative-imports
import { styles } from "./IsraelContactInfo.styles";

export type IsraelContactInfoProps = WithPageData<ContactUsPageProps> &
  WithStyles<typeof styles> & {
    className?: string;
  };

class IsraelContactInfo extends React.PureComponent<IsraelContactInfoProps> {
  public render() {
    const { classes, pageData, className } = this.props;

    return (
      <ContactInfoBlock
        className={className}
        image={pageData.eilat}
        title={
          <span className={classes.title}>
            <T message={"Israel"} />
          </span>
        }
        addressLink="https://goo.gl/maps/K8kmU5vFoQqvjqS7A"
        address="Eilat, Park Amtal, 2, 88000"
        phone="+972(0) 77 97 09 090"
        email="info@trucknet.co.il"
        timeSchedule={
          <>
            <Typography className={classes.workingHours} variant="h5">
              <DaysRange from="Sun" to="Thu" />
              <TimeRange from="9:00" to="19:00" />
            </Typography>
            <Typography variant="h5">
              <DaysRange from="Fri" />
              <TimeRange from="8:00" to="14:00" />
            </Typography>
          </>
        }
      />
    );
  }
}

export default withPageData(withStyles(styles)(IsraelContactInfo));
