import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      width: "280px",
      maxWidth: "100%",
      margin: theme.spacing(10, 0, 0),
    },
    formField: {
      marginTop: theme.spacing(5),
    },
    button: {
      marginTop: theme.spacing(7),
    },
    spinner: {
      color: theme.palette.common.white,
    },
  });
