import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    workingHours: {
      paddingTop: theme.spacing(3),
    },
    title: {
      display: "block",
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
  });
