import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { fetch as fetchPolyfill } from "cross-fetch";
import React from "react";
import { T } from "react-targem";

import { styles } from "./Form.styles";

export interface FormProps extends WithStyles<typeof styles> {
  onMessageSent(): void;
}

interface FormFields {
  name: {
    value: string;
  };
  subject: {
    value: string;
  };
  message: {
    value: string;
  };
  email: {
    value: string;
  };
}

interface FormState {
  submitting: boolean;
}

const POST_MESSAGE_URL =
  "https://fvd69tpyab.execute-api.eu-west-1.amazonaws.com/production/send-form";

class Form extends React.PureComponent<FormProps, FormState> {
  public state: FormState = {
    submitting: false,
  };

  public render() {
    const { classes } = this.props;
    const { submitting } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className={classes.form}>
        <Typography variant="h4" align="left">
          <T message="Contact us" />
        </Typography>
        <TextField
          name="name"
          required
          label={<T message="Your name" />}
          disabled={submitting}
          className={classes.formField}
        />
        <TextField
          name="subject"
          label={<T message="Subject (optional)" />}
          disabled={submitting}
          className={classes.formField}
        />
        <TextField
          name="message"
          required
          label={<T message="Main message" />}
          multiline
          rowsMax={3}
          disabled={submitting}
          className={classes.formField}
        />
        <TextField
          name="email"
          required
          label={<T message="Your email" />}
          disabled={submitting}
          className={classes.formField}
        />
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          disabled={submitting}
          className={classes.button}>
          {submitting ? (
            <CircularProgress size="20" className={classes.spinner} />
          ) : (
            <T message="Send" />
          )}
        </Button>
      </form>
    );
  }

  private setSubmitting = (submitting: boolean) => {
    this.setState({ submitting });
  };

  private postMessage = async (subject: string, text: string) => {
    try {
      return await fetchPolyfill(POST_MESSAGE_URL, {
        method: "POST",
        body: JSON.stringify({
          subject,
          text,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error((error as Error).message);
      alert("Failed to submit. Try again.");
      return;
    }
  };

  private getBodyOfEmail = (form: FormFields & HTMLFormElement) => {
    return `
    A new message has been received on ${new Date().toDateString()}.\n
  
      Name: ${form.name.value}\n
      Subject: ${form.subject.value}\n
      Message: ${form.message.value}\n
      Email: ${form.email.value}
    `;
  };

  private handleSubmit = async (
    event: React.FormEvent<FormFields & HTMLFormElement>,
  ) => {
    event.preventDefault();
    this.setSubmitting(true);
    const subject = "New message from contact form on trucknet.io";
    const text = this.getBodyOfEmail(event.currentTarget);
    const response = await this.postMessage(subject, text);

    if (!response) {
      this.setSubmitting(false);
      return;
    }

    if (this.isValidResponse(response)) {
      this.props.onMessageSent();
      this.setSubmitting(false);
      return;
    }

    const error = await response.json();
    console.error((error as Error).message);
    alert("Failed to submit. Try again.");
    this.setSubmitting(false);
    return;
  };

  private isValidResponse = (response: Response): boolean => {
    return response.status >= 200 && response.status < 300;
  };
}

export default withStyles(styles)(Form);
