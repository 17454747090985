import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { ContactUsPageProps } from "@src/templates/ContactUsPage";

//tslint:disable: no-relative-imports
import ContactInfoBlock from "../ContactInfoBlock";
import DaysRange from "../DaysRange";
import TimeRange from "../TimeRange";
//tslint:enable: no-relative-imports
import { styles } from "./FranceContactInfo.styles";

export type FranceContactInfoProps = WithPageData<ContactUsPageProps> &
  WithStyles<typeof styles> & {
    className?: string;
  };

class FranceContactInfo extends React.PureComponent<FranceContactInfoProps> {
  public render() {
    const { classes, className, pageData } = this.props;

    return (
      <ContactInfoBlock
        className={className}
        image={pageData.france}
        title={<T message={"France"} />}
        addressLink="https://goo.gl/maps/4XvNUKyUy8eoZe2w7"
        address="3 Rue du Commandant Pilot, 92200 Neuilly-sur-Seine, France"
        phone="+33(0) 1 86 96 9999"
        email="info@trucknet.io"
        imgPosition="top center"
        timeSchedule={
          <>
            <Typography className={classes.workingHours} variant="h5">
              <DaysRange from="Mon" to="Thu" />
              <TimeRange from="8:00" to="18:00" />
            </Typography>
            <Typography variant="h5">
              <DaysRange from="Fri" />
              <TimeRange from="8:00" to="13:00" />
            </Typography>
          </>
        }
      />
    );
  }
}

export default withPageData(withStyles(styles)(FranceContactInfo));
