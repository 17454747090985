import { createStyles, Theme } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";

export const styles = (theme: Theme) =>
  createStyles({
    card: {
      display: "flex",
      height: "480px",
      boxShadow: theme.shadows[7],
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        height: "auto",
      },
    },
    cardMedia: {
      width: "40%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    img: {
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "340px",
      },
    },
    textBlock: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "60%",
      height: "100%",
      padding: theme.spacing(3, 5),
      textAlign: "left",
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
        width: "100%",
        height: "auto",
      },
    },
    title: {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    address: {
      paddingBottom: theme.spacing(3),
    },
    phone: {
      paddingBottom: theme.spacing(3),
    },
    email: {
      paddingBottom: theme.spacing(3),
    },
    addressLink: {
      color: blue.A400,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:visited": {
        color: blue.A400,
      },
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    divider: {
      height: "2px",
    },
    scheduleInfo: {
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 0,
      },
    },
  });
