import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";

import { GatsbyQueriedImage } from "@src/types";

import { styles } from "./ContactInfoBlock.styles";

export type ContactInfoBlockProps = WithStyles<typeof styles> & {
  image: GatsbyQueriedImage;
  title: React.ReactNode;
  addressLink?: string;
  address?: string;
  phone: string;
  email: string;
  timeSchedule: React.ReactNode;
  className?: string;
  imgPosition?: string;
};

class ContactInfoBlock extends React.PureComponent<ContactInfoBlockProps> {
  public render() {
    const {
      classes,
      image,
      title,
      address,
      addressLink,
      phone,
      email,
      timeSchedule,
      className,
      imgPosition,
    } = this.props;

    return (
      <Card className={cn(className, classes.card)}>
        <CardMedia className={classes.cardMedia}>
          <GatsbyImage
            fluid={image.childImageSharp.fluid}
            className={classes.img}
            imgStyle={{ objectPosition: imgPosition || "center center" }}
          />
        </CardMedia>
        <CardContent className={classes.textBlock}>
          <div>
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>
            {address && addressLink && (
              <Typography className={classes.address} variant="h5">
                <a
                  className={classes.addressLink}
                  href={addressLink}
                  target="_blank"
                  rel="noreferrer">
                  {address}
                </a>
              </Typography>
            )}
            <Typography className={classes.phone} variant="h5">
              <a className={classes.link} href={`tel:${phone}`} dir="ltr">
                {phone}
              </a>
            </Typography>
            <Typography className={classes.email} variant="h5">
              <a className={classes.link} href={`mailto:${email}`}>
                {email}
              </a>
            </Typography>
          </div>
          <div className={classes.scheduleInfo}>
            <Divider className={classes.divider} />
            {timeSchedule}
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(ContactInfoBlock);
