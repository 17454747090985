import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      width: "100%",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    subtitle: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
  });
