import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import FormBlock from "@src/components/FormBlock";

import { styles } from "./ContactUsTemplate.styles";
import FranceContactInfo from "./FranceContactInfo";
import IsraelContactInfo from "./IsraelContactInfo";
import RomaniaContactInfo from "./RomaniaContactInfo";
import SpainContactInfo from "./SpainContactInfo";

export type ContactUsTemplateProps = WithStyles<typeof styles> & {};

class ContactUsTemplate extends React.Component<ContactUsTemplateProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Container className={classes.container}>
        <div className={classes.titleBlock}>
          <Typography variant="h2" className={classes.title}>
            <T message="Contact us" />
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            <T message="We are always happy to hear from you! For any questions or recommendations do not hesitate to contact us." />
          </Typography>
        </div>
        <FranceContactInfo
          className={cn(classes.officeBlock, classes.firstOffice)}
        />
        <RomaniaContactInfo className={classes.officeBlock} />
        <IsraelContactInfo className={classes.officeBlock} />
        <SpainContactInfo className={classes.officeBlock} />
        <FormBlock
          title={<T message="Do you want to say hello?" />}
          subtitle={
            <T message="For all general queries, including partnership opportunities, press inquiries or technical questions feel free to fill out the form below and we will answer you in no time!" />
          }
          subtitleClassName={classes.formSubtitle}
          className={classes.formBlock}
        />
      </Container>
    );
  }
}

export default withStyles(styles)(ContactUsTemplate);
