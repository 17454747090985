import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
        marginTop: theme.spacing(3),
      },
    },
    titleBlock: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
    subtitle: {
      maxWidth: "588px",
    },
    officeBlock: {
      marginTop: theme.spacing(10),
    },
    firstOffice: {
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(3),
      },
    },
    formBlock: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(20),
    },
    formSubtitle: {
      maxWidth: "646px",
    },
  });
