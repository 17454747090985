// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import ContactUsPage from "@src/templates/ContactUsPage";
export default ContactUsPage;
import { graphql } from "gatsby";

export const cityPhoto = graphql`
  fragment cityPhoto on File {
    childImageSharp {
      fluid(maxWidth: 360) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query ContactPageQuery {
    france: file(relativePath: { eq: "images/offices/france.png" }) {
      ...cityPhoto
    }
    romania: file(relativePath: { eq: "images/offices/romania.png" }) {
      ...cityPhoto
    }
    eilat: file(relativePath: { eq: "images/offices/eilat.png" }) {
      ...cityPhoto
    }
    spain: file(relativePath: { eq: "images/offices/spain.png" }) {
      ...cityPhoto
    }
  }
`;
