import React from "react";

import { WithStyles, withStyles } from "@material-ui/core";
import FormattedDate from "@src/components/FormattedDate";

import { styles } from "./TimeRange.styles";

export interface TimeRangeProps extends WithStyles<typeof styles> {
  /**
   * @example 8:30
   */
  from: string;
  /**
   * @example 18:00
   */
  to: string;
}

export class TimeRange extends React.PureComponent<TimeRangeProps> {
  public render() {
    const { classes, from, to } = this.props;

    return (
      <span className={classes.workTime}>
        <FormattedDate date={this.getDate(from)} format="p" />
        {" - "}
        <FormattedDate date={this.getDate(to)} format="p" />
      </span>
    );
  }

  private getDate(dateString: string): Date {
    const [hours, minutes] = dateString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));
    return date;
  }
}

export default withStyles(styles)(TimeRange);
