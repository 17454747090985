import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { ContactUsPageProps } from "@src/templates/ContactUsPage";

//tslint:disable: no-relative-imports
import ContactInfoBlock from "../ContactInfoBlock";
import DaysRange from "../DaysRange";
import TimeRange from "../TimeRange";
//tslint:enable: no-relative-imports
import { styles } from "./SpainContactInfo.styles";

export type Props = WithPageData<ContactUsPageProps> &
  WithStyles<typeof styles> & {
    className?: string;
  };

const SpainContactInfo = (props: Props) => {
  const { classes, pageData, className } = props;

  return (
    <ContactInfoBlock
      className={className}
      image={pageData.spain}
      title={
        <span className={classes.title}>
          <T message="Spain" />
        </span>
      }
      phone="+34(0) 911 196 357"
      email="spain@trucknet.io"
      timeSchedule={
        <>
          <Typography className={classes.workingHours} variant="h5">
            <DaysRange from="Sun" to="Thu" />
            <TimeRange from="9:00" to="19:00" />
          </Typography>
          <Typography variant="h5">
            <DaysRange from="Fri" />
            <TimeRange from="8:00" to="14:00" />
          </Typography>
        </>
      }
    />
  );
};

export default withPageData(withStyles(styles)(SpainContactInfo));
