import { Typography, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import Form from "./Form";
import { styles } from "./FormBlock.styles";

export interface FormBlockProps extends WithStyles<typeof styles> {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  subtitleClassName?: string;
  className?: string;
}

interface FormBlockState {
  isFormSubmitted: boolean;
}
class FormBlock extends React.PureComponent<FormBlockProps, FormBlockState> {
  public state: FormBlockState = {
    isFormSubmitted: false,
  };

  public render() {
    const {
      classes,
      title,
      subtitle,
      subtitleClassName,
      className,
    } = this.props;
    const { isFormSubmitted } = this.state;

    return (
      <div className={cn(className, classes.container)}>
        {isFormSubmitted ? (
          <>
            <Typography variant="h4" align="center" className={classes.title}>
              <T message="Thanks for your apply" />
            </Typography>
            <Typography variant="h5" align="center">
              <T message="We will contact you soon" />
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" align="center" className={classes.title}>
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={cn(classes.subtitle, subtitleClassName)}>
              {subtitle}
            </Typography>
            <Form onMessageSent={this.handleMessageSent} />
          </>
        )}
      </div>
    );
  }

  private handleMessageSent = () => {
    this.setState({ isFormSubmitted: true });
  };
}

export default withStyles(styles)(FormBlock);
